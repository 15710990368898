<template>
	<nav class="main" :class="{ active: showMenu }">
		<div class="row">
			<div class="columns column12">
				<div class="buttons left">
					<div class="menu-button button right button-en" :class="{ active: showMenu }" @click="toggleMenu">
						<div class="menu-bars" :class="{ active: showMenu }">
							<div class="bar" />
							<div class="bar" />
							<div class="bar" />
						</div>
						<span v-if="showMenu">{{ $t('close') }}</span>
						<span v-else>MENU</span>
					</div>
				</div>
				<nuxt-link
					class="logo"
					:class="page.headingImage ? '' : 'black'"
					:to="{ path: localePath('/'), hash: route.name.startsWith('index') ? '#top' : '' }"
				/>
				<div class="nav-container">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
					</ul>
					<div class="nav-footer">
						<social-media :socials="socials" />
						<ul v-if="page" class="lang-nav">
							<li v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									{{ $t(item.language.toLowerCase()) }}
								</nuxt-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="buttons">
					<book-button class="button button-cta reserve-now right">
						<span>{{ $t('bookNow') }}</span>
					</book-button>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { socials } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
};

const hideMenu = () => {
	showMenu.value = false;
};

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.menu-button');
	if (window.pageYOffset >= 350) {
		fixedElements.forEach((el) => el.classList.add('dark'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('dark'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
nav {
	width: 100%;
	padding: 15px 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: all 0.3s ease-in-out;

	.row {
		overflow: visible;
	}
}

.logo {
	width: 280px;
	height: 151px;
	background: url('~/assets/images/logo-hotel-aalders-full.png') no-repeat center center;
	background-size: 280px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 2;
}

.no-slider {
	padding: 5px 0;

	.logo {
		background-size: 140px;
		width: 140px;
		height: 76px;
	}
}

.main-nav {
	list-style: none;
	text-align: left;
	font-weight: 500;
	line-height: 26px;
	width: 100%;
	float: left;
	visibility: visible;
	font-family: $heading-font-family;

	& > li {
		display: block;
		float: left;
		margin: 10px 0;
		width: 100%;
	}

	a {
		color: #443c5c;
		padding: 5px 0;
		font-weight: 300;
		font-size: 20px;
		text-decoration: none;
		transition: color 0.3s ease-in-out;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}

		&.router-link-exact-active::before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			background: url('~/assets/images/pre-subtitle-icon.png') no-repeat center center;
			background-size: 24px;
			margin: 0 10px 0 0;
		}
	}
}

.nav-footer {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
	margin: 50px 0 0;
	float: left;

	:deep(.socials a) {
		color: #443c5c;
		background: #fff;
		border-radius: 0;
		width: 42px;
		height: 45px;
		line-height: 45px;
		margin: 0 10px 0 0;
		font-size: 20px;
	}
}

.contact-info {
	float: left;
	width: 45%;
}

.lang-nav {
	list-style: none;
	text-align: left;
	display: flex;
	flex-flow: row nowrap;
	line-height: 26px;
	justify-content: flex-end;
	align-items: center;

	a {
		color: #000;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}
	}

	.flag::before {
		content: '';
		width: 16px;
		position: relative;
		top: 2px;
		margin-right: 7px;
		height: 16px;
		display: inline-block;
	}

	.flag.en::before {
		background: url('~/assets/images/en.png') no-repeat;
	}

	.flag.nl::before {
		background: url('~/assets/images/nl.png') no-repeat;
	}

	.flag.de::before {
		background: url('~/assets/images/de.png') no-repeat;
	}

	.flag.fr::before {
		background: url('~/assets/images/fr.png') no-repeat;
	}

	.flag.es::before {
		background: url('~/assets/images/es.png') no-repeat;
	}

	li {
		margin: 0 10px;
	}
}

.nav-container {
	position: fixed;
	top: 0;
	right: -105vw;
	bottom: 0;
	background: #fff;
	min-width: 36%;
	width: 780px;
	max-width: 100%;
	padding: 150px 100px 20px;
	z-index: 100;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
	transition: right 0.3s ease-in-out;
}

.active .nav-container {
	right: 0;
	transition: right 0.5s ease-in-out;

	&::before {
		content: '';
		display: block;
		position: absolute;
		inset: 0;
		background: #f6f5fa;
		z-index: 1;
	}

	&::after {
		content: '';
		display: block;
		position: fixed;
		inset: 0;
		z-index: -1;
		background: rgba(34 28 53 / 85%);
		backdrop-filter: blur(24px);
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		position: relative;
		z-index: 2;
	}
}

.nav-overlay {
	display: none;
	position: fixed;
	inset: 0;
	background: rgb(0 0 0 / 30%);
	z-index: 99;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	&.active {
		display: block;
		opacity: 1;
	}
}

nav .buttons {
	position: fixed;
	top: 15px;
	right: 20px;
	z-index: 101;

	&.left {
		right: auto;
		left: 20px;
	}

	.button {
		padding: 17px 34px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
			width: 1em;
		}
	}

	.menu-button {
		cursor: pointer;
		border: none;
		padding: 15px 15px 14px 5px;
		z-index: 3;
		position: relative;
		min-height: 40px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background: none;
		}

		svg {
			color: #fff;
			font-size: 36px;
		}

		span {
			font-size: 15px;
			letter-spacing: 3px;
			margin: 0 0 0 25px;
			color: #fff;
			font-weight: 300;
		}

		&.dark {
			color: #000;
			background: #f6f5fa;

			span {
				color: #000;
			}

			.menu-bars {
				.bar {
					background: #000;
				}
			}
		}

		.menu-bars {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;
			width: 56px;

			.bar {
				width: 38px;
				margin: 5px 0;
				background: #fff;
				height: 2px;
				border-radius: 5px;
				transition: all 0.3s ease-in-out;

				&:first-child {
					margin: 0 0 5px 18px;
				}

				&:last-child {
					margin: 5px 0 0 18px;
				}
			}

			&.active {
				margin-top: 2px;
				background: none !important;

				.bar {
					display: none;

					&:first-child {
						display: block;
						transform: rotate(45deg);
						margin-bottom: -6px;
					}

					&:last-child {
						display: block;
						transform: rotate(-45deg);
						margin-top: 4px;
					}
				}
			}
		}
	}
}

nav.no-slider .buttons {
	.menu-button {
		background: #f6f5fa;

		.menu-bars {
			.bar {
				background: #000;
			}
		}

		span {
			color: #000;
		}
	}

	.menu-button.active {
		background: none !important;

		.menu-bars {
			.bar {
				background: #fff;
			}
		}

		span {
			color: #fff;
		}
	}
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}

	nav .buttons {
		.menu-button.active {
			.menu-bars {
				.bar {
					background: #000;
				}
			}

			span {
				color: #000;
			}
		}
	}

	nav.no-slider .buttons {
		.menu-button.active {
			.menu-bars {
				.bar {
					background: #000;
				}
			}

			span {
				color: #000;
			}
		}
	}

	.logo {
		width: 200px;
		height: 108px;
		background: url('~/assets/images/logo-hotel-aalders-medium.png') no-repeat center center;
		background-size: 200px;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 100px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	.contact-info {
		display: none;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		&.reserve-now {
			display: none;
		}
	}

	.nav-footer {
		margin: 25px 0 0;
	}

	.active .nav-container {
		&::after {
			display: none;
		}
	}

	.logo {
		width: 140px;
		height: 76px;
		background: url('~/assets/images/logo-hotel-aalders-small.png') no-repeat center center;
		background-size: 140px;
		left: 110px;
		right: auto;
	}

	.menu-button {
		span {
			display: none;
		}
	}
}
</style>
