<template>
	<div id="top" class="container">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation :class="page.headingImage ? '' : 'no-slider'" />

		<slot />

		<footers />
		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image-webp="defaults[locale].popUp.imageWebp"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content"
		/>
		<cookie-consent v-if="defaults[locale].cookieConsent.published" :item="defaults[locale].cookieConsent" />
		<to-top />
		<book-button type="fixedmobile" class="button button-cta fixedmobile reserve-now right">
			<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
			<span>{{ $t('bookNow') }}</span>
		</book-button>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo-hotel-aalders-full.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.reserve-now svg {
	margin-right: 7px;
}
</style>
